import React from "react";

const w012322 = () => {
  return (
    <>
      <p className="weekName">Week of 1/23/22</p>
      <ul>
        <li>
          <a href="https://news.artnet.com/market/picasso-nft-2064495">
            Picasso’s Granddaughter and Her DJ Son Are Minting 1,010 NFTs Based
            on a Ceramic Dish the Artist Made in 1958
          </a>
        </li>
        <li>
          <a href="https://www.theartnewspaper.com/2022/01/27/french-national-assembly-approves-return-of-15-nazi-looted-worksincluding-paintings-by-chagall-and-klimtto-jewish-heirs">
            French National Assembly approves return of 15 Nazi-looted
            works—including paintings by Chagall and Klimt—to Jewish heirs
          </a>
        </li>
        <li>
          <a href="https://www.dailymail.co.uk/tvshowbiz/article-10441859/EDEN-CONFIDENTIAL-15-years-Damien-Hirst-reveals-100m-skull-buried.html">
            After 15 years, Damien Hirst reveals where his $100m skull is buried
          </a>
        </li>
        <li>
          <a href="https://www.theguardian.com/music/2022/jan/27/i-thought-i-could-be-dead-by-the-end-of-this-cate-le-bon-on-making-art-from-covid-chaos">
            ‘I thought: I could be dead by the end of this’: Cate Le Bon on
            making art from Covid chaos
          </a>
        </li>
        <li>
          <a href="https://www.artsy.net/article/artsy-editorial-8-artists-making-nfts">
            Why These 8 Artists Are Making NFTs
          </a>
        </li>
      </ul>
    </>
  );
};

export default w012322;
