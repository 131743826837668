import React from "react";

const w012322 = () => {
  return (
    <>
      <p className="weekName">Week of 1/30/22</p>
      <ul>
        <li>
          <a href="https://www.artnews.com/art-news/news/picasso-nft-project-family-cancels-sale-1234617137/">
            Picasso Family Says Reports of Major NFT Sale Are ‘Completely Wrong’
          </a>
        </li>
      </ul>
    </>
  );
};

export default w012322;
