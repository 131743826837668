import "./App.css";
import W013022 from "./weeks/W013022.js";
import W012322 from "./weeks/W012322.js";

function App() {
  return (
    <>
      <div className="pageContainer">
        <h1 className="homepageH1">Contemporary Art Weekly</h1>
        <h2>News and information</h2>
        <hr />
        <W013022 />

        <W012322 />
      </div>
    </>
  );
}

export default App;
